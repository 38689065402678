
    import { useI18next } from '@composables/i18next';
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { OutgoingAffiliate } from '@roc/affiliate';
    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',

        components: {
            OutgoingAffiliate,
        },

        setup () {
            const { t, vT } = useI18next(['footer', 'links', 'global']);
            return { t, vT };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

